import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_thumbnail = _resolveComponent("thumbnail")!
  const _component_b_advanced_table = _resolveComponent("b-advanced-table")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, { full: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_b_advanced_table, {
        "table-array": [_ctx.table],
        "sticky-header": "calc(100vh - 105px)",
        loading: _ctx.state.loading,
        "dynamic-columns": ""
      }, {
        toprow: _withCtx(() => [
          _createVNode(_component_b_button, {
            size: "sm",
            variant: "tertiary",
            disabled: !_ctx.Permissions.ADMINISTRATION.canEditItems(),
            "disabled-message": _ctx.getTranslation('core.common.youDoNotHavePermissionToAddItems'),
            onClick: _ctx.openAdd
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.addItem')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "disabled-message", "onClick"])
        ]),
        action: _withCtx((row) => [
          _createVNode(_component_b_dropdown, {
            "no-caret": "",
            "close-on-click": ""
          }, {
            "button-content": _withCtx(() => [
              _createVNode(_component_faicon, { icon: "ellipsis-h" })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_b_dropdown_item, {
                size: "sm",
                disabled: !_ctx.Permissions.ADMINISTRATION.canEditItems(),
                onClick: () => _ctx.openEdit(row.item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.edit')), 1)
                ]),
                _: 2
              }, 1032, ["disabled", "onClick"]),
              _createVNode(_component_b_dropdown_item, {
                size: "sm",
                onClick: () => _ctx.openItemHistory(row.item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.showHistory')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 2
          }, 1024)
        ]),
        "cell(imageUrlThumb)": _withCtx((row) => [
          _createVNode(_component_thumbnail, {
            "image-url-thumb": row.item.imageUrlThumb,
            "image-url-full": row.item.imageUrlFull
          }, null, 8, ["image-url-thumb", "image-url-full"])
        ]),
        _: 1
      }, 8, ["table-array", "loading"])
    ]),
    _: 1
  }))
}