
import {
    computed, defineComponent, h, onBeforeMount, reactive,
} from 'vue';
import { useRouter } from 'vue-router';
import { TableDefinition } from '@/types';
import ItemService from '@/services/ItemService';
import Screen from '@/components/layout/Screen.vue';
import Item from '@/domain/Item';
import Permissions from '@/services/permissions/Permissions';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import { itemMetaData } from '@/meta-data/item';
import { useUnitOfMeasureForItem } from '@/measurement/useUnitOfMeasure';
import UomBadge from '@/components/UomBadge.vue';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import EntityType from '@/domain/enums/EntityTypes';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BDropdownItem from '@/components/bootstrap-library/BDropdownItem.vue';
import BDropdown from '@/components/bootstrap-library/BDropdown.vue';
import Thumbnail from '@/components/Thumbnail.vue';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';

type State = {
    items: Array<Item>;
    showForm: boolean;
    loading: boolean;
    modalTitle: string;
    saving: boolean;
};

export default defineComponent({
    name: 'master-data-item-list',
    components: {
        Thumbnail,
        BDropdown,
        BDropdownItem,
        BButton,
        Screen,
        BAdvancedTable,
    },
    setup() {
        const itemService = new ItemService();
        const router = useRouter();

        const {
            useItemHeightUom, useItemLengthUom, useItemWidthUom, useItemWeightUom, useItemCollapsedHeightUom,
        } = useUnitOfMeasureForItem(itemMetaData);

        const state = reactive<State>({
            showForm: false,
            items: [],
            loading: true,
            modalTitle: '',
            saving: false,
        });

        onBeforeMount(async () => {
            const response = await itemService.getAllItems();
            if (response.success) {
                state.items = response.items;
                state.loading = false;
            }
        });

        const table = computed(
            (): TableDefinition<Item> => ({
                // TODO: BAdvancedTable relies on looking at data types on properties of
                // the first element in items. But this doesn't work if the first item has a null value for something
                // needed as a search option.
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                items: state.items.sort((a, b) => a.id - b.id),
                key: 'itemList',
                name: getTitleCaseTranslation('core.domain.itemTable'),
                columnDefinition: [
                    {
                        label: getTitleCaseTranslation('core.domain.image'),
                        key: 'imageUrlThumb',
                    },
                    {
                        label: getTitleCaseTranslation('core.domain.itemNumber'),
                        key: 'customerItemNumber',
                        searchable: true,
                    },
                    {
                        key: 'name',
                        label: getTitleCaseTranslation('core.domain.name'),
                        searchable: true,
                    },
                    {
                        key: 'shortName',
                        label: getTitleCaseTranslation('core.domain.shortName'),
                        searchable: true,
                    },
                    {
                        key: 'height',
                        label: getTitleCaseTranslation('core.domain.height'),
                        searchable: true,
                        uom: useItemHeightUom,
                        headerBadge: () => h(UomBadge, {
                            uom: useItemHeightUom,
                            onClick: useItemHeightUom?.toggleUnitOfMeasure,
                        }),
                    },
                    {
                        key: 'collapsible',
                        label: getTitleCaseTranslation('core.domain.collapsible'),
                        searchable: true,
                    },
                    {
                        key: 'collapsedHeight',
                        label: getTitleCaseTranslation('core.domain.collapsedHeight'),
                        searchable: true,
                        uom: useItemCollapsedHeightUom,
                        headerBadge: () => h(UomBadge, {
                            uom: useItemCollapsedHeightUom,
                            onClick: useItemCollapsedHeightUom?.toggleUnitOfMeasure,
                        }),
                    },
                    {
                        key: 'width',
                        label: getTitleCaseTranslation('core.domain.width'),
                        searchable: true,
                        uom: useItemWidthUom,
                        headerBadge: () => h(UomBadge, {
                            uom: useItemWidthUom,
                            onClick: useItemWidthUom?.toggleUnitOfMeasure,
                        }),
                    },
                    {
                        key: 'length',
                        label: getTitleCaseTranslation('core.domain.length'),
                        searchable: true,
                        uom: useItemLengthUom,
                        headerBadge: () => h(UomBadge, {
                            uom: useItemLengthUom,
                            onClick: useItemLengthUom?.toggleUnitOfMeasure,
                        }),
                    },
                    {
                        key: 'weight',
                        label: getTitleCaseTranslation('core.domain.weight'),
                        searchable: true,
                        uom: useItemWeightUom,
                        headerBadge: () => h(UomBadge, {
                            uom: useItemWeightUom,
                            onClick: useItemWeightUom?.toggleUnitOfMeasure,
                        }),
                    },
                    {
                        key: 'palletQty',
                        label: getTitleCaseTranslation('core.domain.palletQty'),
                        searchable: true,
                    },
                    {
                        label: getTitleCaseTranslation('core.domain.isUnitLoad'),
                        key: 'isUnitLoad',
                        searchable: true,
                    },
                    {
                        label: getTitleCaseTranslation('core.domain.itemType'),
                        searchable: true,
                        key: ['type', 'description'],
                    },
                    {
                        key: 'unitCost',
                        label: getTranslation('core.domain.unitCostUSD'),
                        searchable: true,
                    },
                ],
            }),
        );

        function openAdd() {
            router.push({
                name: MasterDataRouteTypes.ITEM.ADD,
            });
        }

        function openEdit(item: Item) {
            router.push({
                name: MasterDataRouteTypes.ITEM.EDIT,
                params: {
                    itemId: item.id,
                },
            });
        }

        async function openItemHistory(item: Item) {
            router.push({
                name: MasterDataRouteTypes.HISTORY.LIST,
                params: { entityType: EntityType.ITEM, entityId: item.id },
            });
        }

        return {
            state,
            openAdd,
            openEdit,
            openItemHistory,
            table,
            getTitleCaseTranslation,
            getTranslation,
            Permissions,
        };
    },
});
